@font-face {
	font-family: 'Pragmatica-Bold';
	src: local('Pragmatica-Bold'),
		url('../../fonts/Pragmatica-Bold.ttf') format('truetype');
}

@font-face {
	font-family: 'Pragmatica';
	src: local('Pragmatica'),
		url('../../fonts/Pragmatica-Book.ttf') format('truetype');
}

* {
	margin: 0;
	padding: 0;
	max-width: 100%;
	font-family: 'IBM Plex Sans', sans-serif !important;
	font-style: normal !important;
}

html,
body {
	max-width: 100%;
	overflow-x: hidden;
}

input {
	padding: 10px !important;
}

.tg-bg-blue {
	background-color: #152f60;
	padding-top: 25px;
	padding-bottom: 25px;
	min-height: 100vh;
}

.tg-logo {
	width: 5.714em;
	height: 5.714em;
	display: block;
	margin: auto;
}

.tg-programmer {
	display: block;
	margin: auto;
}

.tg-h3 {
	color: white;
	text-align: center;
	font-weight: bold;
	line-height: 1.1em;
}

.tg-para {
	padding: 0.7em;
	text-align: center;
	color: white;
}

.tg-scan-wrapper {
	display: block;
	margin: auto;
	text-align: center;
	padding-top: 1.42857em;
	padding-bottom: 1.42857em;
	cursor: pointer;
}

.tg-barcode-scanner {
	width: 2em;
	height: 2em;
	margin-top: 0.714em;
	margin-bottom: 0.714em;
	display: inline-block;
	margin: auto;
}

.tg-scan-qr {
	display: inline-block;
	margin: auto;
	color: #ffffff;
	margin-left: 0.714em;
	margin-bottom: -8px;
	font-weight: bold;
}

.tg-login {
	background-color: white;
	color: #152f60;
	display: block;
	margin: auto;
	width: 70%;
	font-weight: bold;
	font-size: 1.1em;
	margin-top: 1.42857em;
}

.tg-btn-blue {
	background-color: #152f60;
	border: 1px solid #152f60;
	color: white;
	display: block;
	margin: auto;
	width: 100%;
	font-weight: bold;
	font-size: 1.1em;
	margin-top: 1.42857em;
	border-radius: 5px;
	border-width: 1;
	padding: 0.714em;
}

.tg-btn-blue-outline {
	background-color: white;
	color: #152f60;
	display: block;
	margin: auto;
	width: 100%;
	font-weight: bold;
	font-size: 1.1em;
	margin-top: 1.42857em;
	border-radius: 5px;
	border-color: #152f60;
	border-width: 1;
	padding: 0.714em;
}

.tg-btn-red {
	background-color: #da3451;
	color: white;
	margin: auto;
	font-weight: 500;
	font-size: 1rem;
	border-radius: 4px;
	padding: 9.5px 14px;
}

.tg-btn-red:hover,
.tg-btn-red:active {
	color: white;
}

.tg-w50 {
	width: 40%;
}

.tg-mt-10 {
	margin-top: 25px !important;
}

.tg-mgb-20 {
	margin-bottom: 2em;
}

.tg-mgb-10 {
	margin-bottom: 1em;
}

.tg-text-blue {
	text-align: center;
	text-decoration: none;
	color: #152f60;
	display: block;
	padding: 0.714em;
}

.navbar {
	border-bottom: solid 1px #ebebeb;
}
.bottom-tab-nav {
	position: fixed;
	bottom: 0;
	z-index: 3;
	border-top: solid 1px #ebebeb;
}
.nav-link,
.bottom-nav-link {
	color: #55575b;
}
.nav-link.active {
	color: #c94443 !important;
}
.bottom-tab-label {
	font-size: 12px;
}

.nav-link:focus,
.nav-link:hover {
	color: #55575b;
}

.tg-preview-modal {
	max-width: unset;
}

.tg-preview-modal .tg-btn-red {
	margin: unset;
}

.tg-container {
	background-color: #152f60;
	color: white;
	border-bottom: 1px solid #152f60;
	border-bottom-left-radius: 1.42857em;
	border-bottom-right-radius: 1.42857em;
	position: relative;
	padding: 1em;
	margin: 0;
}

.tg-container-white {
	padding: 2em;
	min-height: 100vh;
}

.tg-signature {
	height: 4.2em;
	width: 38%;
	display: block;
	margin: auto;
	margin-bottom: 0.714em;
}

.tg-center-img {
	display: block;
	margin: auto;
	text-align: center;
}

.tg-qr-code {
	width: 100%;
	height: 100%;
	display: block;
	margin: auto;
}

.tg-print-wrap {
	padding-bottom: 100px !important;
}

#HASH {
	width: 100%;
}

#HASH button {
	display: inline-block;
}

.tg-tab-label {
	font-weight: 600;
	font-size: 0.875em;
	line-height: 1.2em;
	text-align: center;
}

.tg-navbar {
	padding-top: 0 !important;
	padding-bottom: 0 !important;
	box-shadow: 0px -3px 24px rgba(0, 0, 0, 0.1) !important;
	border-radius: 0px !important;
}

.tg-balance {
	width: 100%;
	max-width: 430px;
}

.standard-text {
	font-weight: 500;
	font-size: 1em;
	line-height: 1.5em;
	text-align: center;
	color: #151a30;
}

.tg-nav-item {
	display: block;
	margin: auto;
}

.tg-tab-icon {
	display: block;
	margin: auto;
	height: 2em;
	width: 2em;
}

.tg-home {
	background-image: url('../img/home.svg');
	background-repeat: no-repeat;
	background-position: center;
}

.tg-home-active {
	background-image: url('../img/home_active.svg');
}

.tg-qrcode {
	background-image: url('../img/qrcode.svg');
	background-repeat: no-repeat;
	background-position: center;
}

.tg-qrcode-active {
	background-image: url('../img/qrcode_active.svg');
}

.tg-print {
	background-image: url('../img/print.svg');
	background-repeat: no-repeat;
	background-position: center;
}

.tg-print-active {
	background-image: url('../img/print_active.svg');
}

.tg-logout {
	background-image: url('../img/logout.svg');
	background-repeat: no-repeat;
	background-position: center;
}

.tg-logout-active {
	background-image: url('../img/logout_active.svg');
}

.tg-header {
	font-weight: 500;
	font-size: 1.42857em;
	line-height: 1.8571em;
	color: #ffffff;
}

.tg-header-txt {
	font-weight: normal;
	font-size: 0.9em;
	line-height: 1.1428em;
	overflow: hidden;
}

.txt-ceo-name {
	font-weight: 500;
	font-size: 1.14285;
	line-height: 1.5em;
	text-align: center;
}

.txt-ceo {
	font-weight: 500;
	font-size: 1em;
	line-height: 1.2857;
	color: #c94443;
	text-align: center;
}

.tg-strong {
	font-weight: 500;
	font-size: 1.1em;
	line-height: 1.642857em;
	color: #151a30;
}

.tg-normal {
	font-size: 1em;
	line-height: 1.642857em;
	color: #151a30;
}

.tg-valid-span {
	padding: 0.71428em;
	background: rgba(94, 165, 5, 0.1);
	border-radius: 2.2857em;
	width: max-content;
	display: block;
	margin: auto;
	margin-bottom: 1.4em;
	text-align: center;
}

.tg-invalid-span {
	padding: 0.71428em;
	background: rgba(227, 143, 143, 0.1);
	border-radius: 2.2857em;
	width: max-content;
	display: block;
	margin: auto;
	margin-bottom: 1.4em;
	text-align: center;
}

.tg-valid-txt {
	font-weight: 600;
	font-size: 1em;
	line-height: 1em;
	text-align: center;
	color: #5ea505;
	margin: 0px 10px;
}

.tg-invalid-txt {
	font-weight: 600;
	font-size: 1em;
	line-height: 1em;
	text-align: center;
	color: #c94443;
	margin: 0px 10px;
}

.tg-underline {
	border-bottom: 1px solid #000;
	width: 45%;
	margin: auto;
	margin-bottom: 0.5em;
}

.tg-modal {
	margin: 0;
	width: 100%;
	position: absolute;
	bottom: 0 !important;
	border-width: 1px;
	border-top-right-radius: 1.8em;
	border-top-left-radius: 1.8em;
}

.tg-verify-modal {
	margin: 0;
	border-radius: 6px;
	max-width: 520px !important;
	width: 100%;
}

.tg-verify-modal .modal-title,
.tg-verify-modal .form-label {
	color: #151a30;
}

.tg-verify-modal .form-label {
	font-weight: 500;
}

.tg-verify-modal .instructions li {
	font-size: 11px;
}

.tg-verify-modal p {
	color: #3e4857;
}

.tg-verify-modal .modal-footer {
	background-color: #f5f7fa40;
}

.tg-verify-modal .tg-btn-red {
	min-width: 140px;
	margin: unset;
}

.tg-verify-modal .modal-header .btn-close {
	position: absolute;
	right: 0;
}

.tg-verify-modal .modal-body p.steps {
	color: #8f9bb3;
	margin-bottom: 20px;
}

.tg-verify-modal .upload-container div label {
	border: 2px solid #edf1f7;
	width: 160px;
	height: 160px;
	border-radius: 50%;
	background-color: #062e64;
	box-shadow: 0px 3px 8px 0px #8f9bb326;
}

.tg-verify-modal .upload-container {
	grid-template-columns: 160px 1fr;
	column-gap: 42px;
}

.tg-verify-modal .upload-container img {
	object-fit: cover;
	object-position: center;
}

.tg-verify-modal .upload-container div label input {
	position: absolute;
	width: 0;
	height: 0;
	display: none;
}

.tg-verify-modal .upload-container .btn-edit {
	position: absolute;
	width: 24px;
	height: 24px;
	bottom: 6px;
	right: 12px;
	box-shadow: 0px 3px 8px 0px #8f9bb326;
	background-color: #ffffff;
	border-radius: 50%;
}

.modal.fade .modal-dialog {
	-webkit-transform: translate3d(0, 0%, 0) !important;
	transform: translate3d(0, 0%, 0) !important;
}

.btn-close {
	padding: 1.5em !important;
	padding-bottom: 0 !important;
}

.tg-hd-txt {
	font-weight: 600;
	font-size: 24px;
	line-height: 31px;
	display: flex;
	align-items: center;
	color: #151a30;
}

.tg-bb-0 {
	border-bottom: 0 !important;
}

.tg-cancel-scan-btn {
	margin: 0;
	border-width: 0;
	border-radius: 0;
	margin-top: -0.38em;
	position: absolute;
	bottom: 0;
	z-index: 20;
	outline: none;
}

@media screen and (max-width: 768px) {
	.tg-preview-modal {
		width: 100%;
	}
}

@media screen and (min-width: 768px) {
	.tg-login-wrap {
		display: flex;
		align-items: center;
	}

	.tg-login-inner {
		width: 100%;
	}
}

.checkbox-container {
	padding: 10px;
}

.table-row-default {
	cursor: default;
}

.table-cell-clickable {
	cursor: pointer;
}

.developer-name-container {
	display: flex;
	align-items: center;
	gap: 16px;
}

/* ADMIN DASHBOARD */
/* Base container styles */
.base_container {
	background-color: #ffffff;
	min-height: 100vh;
}

@media (min-width: 600px) {
	.admin_base_container {
		background-color: #f4f4f4;
	}
}

/* Dashboard styles */
.admin_dashboard {
	padding: 16px;
}

@media (min-width: 600px) {
	.admin_dashboard {
		padding: 24px;
	}
}

/* Statistics section */
.admin_statistics {
	display: flex;
	justify-content: space-around;
	margin-bottom: 16px;
}

@media (min-width: 600px) {
	.admin_statistics {
		flex-direction: row;
		margin-bottom: 24px;
	}
}

/* Controls section (SearchBar and DropdownMenu) */
.admin_controls {
	display: flex;
	justify-content: space-between;
}

@media (min-width: 600px) {
	.admin_controls {
		justify-content: space-between;
		margin-bottom: 24px;
	}
}

/* Table styles */
.admin_developer_table {
	margin-bottom: 16px;
}

@media (min-width: 600px) {
	.admin_developer_table {
		margin-bottom: 24px;
	}
}

/* Activity table styles */
.admin_activity_table {
	margin-bottom: 16px;
}

@media (min-width: 600px) {
	.admin_activity_table {
		margin-bottom: 24px;
	}
}

/* Navigation section */
.admin_navigation {
	margin-top: 64px;
}

/* SEARCH BAR */
/* Form styles */
.admin_form {
	display: flex;
	align-items: center;
	margin-right: 20px;
	border: 1px solid #ccc;
	border-radius: 5px;
	overflow: hidden;
}

/* Input field styles */
.admin_input {
	flex: 1;
	padding: 10px;
	border: none;
	outline: none;
}

/* Button styles */
.admin_button {
	background-color: #fff;
	border: none;
	cursor: pointer;
	padding: 10px;
	outline: none;
}

/* Icon styles */
.admin_icon {
	width: 20px;
}

/* Status Styles */
/* Valid Status */
.active-status {
	background-color: #c8e6c9;
	color: #219653;
	padding: 5px 10px;
	border-radius: 10px;
	text-transform: capitalize;
}

/* Expired Status */
.expired-status {
	background-color: #ffcdd2;
	color: #c62828;
	padding: 5px 10px;
	border-radius: 10px;
	text-transform: capitalize;
}

/* Pending Status */
.pending-status {
	background-color: #8f9bb3;
	color: #f0f0f0;
	padding: 5px 10px;
	border-radius: 10px;
	text-transform: capitalize;
}

/* Unknown Status */
.unknown-status {
	background-color: #e0e0e0;
	color: #757575;
	padding: 5px 10px;
	border-radius: 10px;
	text-transform: capitalize;
}

/* ACTIVITY TABLE */
/* Container styles */
.admin_activity-container {
	background-color: #fff;
	border-radius: 4px;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
	padding: 20px;
}

/* Table styles */
.admin_activity-table {
	margin: 20px 0;
	max-height: 300px;
	overflow-y: auto;
}

/* Title styles */
.admin_activity-title {
	font-size: 20px;
	font-weight: bold;
	margin-bottom: 15px;
}

/* Row styles */
.admin_activity-row {
	padding: 10px;
	border-bottom: 1px solid #ccc;
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
}

/* Avatar styles */
.admin_activity-avatar {
	width: 50px;
	height: 50px;
	border-radius: 50%;
	background-color: #666;
	margin-right: 10px;
}

/* Message styles */
.admin_activity-message {
	display: flex;
	flex-direction: column;
	text-align: left;
	flex-grow: 1;
}

/* Text styles */
.admin_activity-text {
	font-weight: bold;
}

/* Date styles */
.admin_activity-date {
	font-size: 0.8rem;
	color: #666;
}

/* SIDEBAR */
/* Sidebar Container */
.admin_sidebar-container {
	position: fixed;
	right: 0;
	top: 0;
	bottom: 0;
	width: 100%;
	max-width: 500px;
	background-color: #fff;
	box-shadow: -2px 0 8px rgba(0, 0, 0, 0.2);
	padding: 20px;
	z-index: 1000;
	display: flex;
	flex-direction: column;
}

/* Header Container */
.admin_sidebar-header-container {
	display: flex;
	align-items: center;
	margin-bottom: 20px;
}

/* Close Button */
.admin_sidebar-close-button {
	display: flex;
	background: none;
	border: none;
	align-self: flex-start;
	margin-bottom: 10px;
	margin-right: 20px;
	font-size: 20px;
	cursor: pointer;
}

/* Header */
.admin_sidebar-header {
	text-align: left;
	font-size: 18px;
	font-weight: bold;
	flex-grow: 1;
}

/* Tabs Container */
.admin_sidebar-tabs-container {
	display: flex;
	justify-content: space-around;
}

/* Tab Button */
.admin_sidebar-tab-button {
	background: none;
	border: none;
	font-size: 16px;
	font-weight: bold;
	padding-bottom: 10px;
	cursor: pointer;
}

/* Active Tab */
.admin_sidebar-active-tab {
	border-bottom: 2px solid #3e4857;
	color: #000;
}

/* Inactive Tab */
.admin_sidebar-inactive-tab {
	border-bottom: none;
	color: #888;
}

/* ID Image */
.admin_sidebar-id-image {
	width: 120px;
	height: 120px;
	border-radius: 50%;
	object-fit: cover;
	margin-bottom: 20px;
}

/* Arrow Icon */
.admin_sidebar-arrow {
	width: 1.5em;
}
/* Row Container */
.admin_sidebar-activity-row {
	display: flex;
	align-items: center;
	margin-bottom: 15px;
}

/* Avatar */
.admin_sidebar-activity-row-avatar {
	width: 50px;
	border-radius: 50%;
	margin-right: 10px;
}

/* Bold Text */
.admin_sidebar-activity-row-text-bold {
	font-size: 14px;
	font-weight: bold;
	margin: 0;
}

/* Secondary Text */
.admin_sidebar-activity-row-text-secondary {
	font-size: 12px;
	color: #888;
	margin: 0;
}

/* DEVELOPER ID PREVIEW */
/* Developer Preview Card */
.admin_developer-preview-card {
	position: relative;
	z-index: 5;
	max-width: 350px;
	max-height: 520px;
	margin: 20px auto;
	background-color: #ffffff;
	box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
	border-radius: 10px !important;
	border: 1px solid #d9d9d9;
	padding: 30px;
	text-align: center;
	overflow: hidden;
	-webkit-border-radius: 10px !important;
	-moz-border-radius: 10px !important;
	-ms-border-radius: 10px !important;
	-o-border-radius: 10px !important;
}

/* Media Container */
.admin_developer-preview-media {
	height: 290px;
	width: 290px;
	border-radius: 15px;
	background-color: #f5f5f5;
	background-position: center;
	border: 2px solid rgba(0, 0, 0, 0.05);
}

/* Developer Content */
.admin_developer-preview-content {
	text-align: left;
}
